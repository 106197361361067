/**
 * Auth hook for registering
 */

// sweetalert2 components
import Swal from "sweetalert2";

import { useState } from 'react';

import { REQUESTSTATE } from '../constants/request';
import { createAdminUser } from "api/auth";
import { getExceptionMessage } from "../utils/exceptions";

/**
 * Auth hook for registering a user as an admin
 * @returns {Object} Auth utilities
 */
export const useRegisterAdmin = () => {

    const [registeredUser, setRegisteredUser] = useState(null); 
    const [requestState, setRequestState] = useState(REQUESTSTATE.IDLE);
    const [error, setError] = useState(null);

    /**
     * Handles the user profile submit
     * @param {*} values - User profile values
     * @param {*} actions - Formik actions
     */
    const handleUserProfileSubmit = async (values, actions) => {

        setRequestState(REQUESTSTATE.PENDING)
        setError(null);

        try {
            const newUser = await createAdminUser({
                email: values.email,
                password: values.password,
                name: values.firstName,
                surname: values.lastName,
                customer: {
                    name: values.company,
                    tax_id_data: [{
                        type: values.taxIDType,
                        value: values.taxID
                    }]
                }
              });
            setError(null);
            setRequestState(REQUESTSTATE.SUCCESS);

            Swal.fire("Usuario registrado correctamente", "Te enviaremos un correo electrónico para poder verificarte.", "success");
            setRegisteredUser(newUser);          

        } catch (error) { 
            Swal.fire("¡Error!", getExceptionMessage(error?.response?.data?.detail?.message), "error");
            const status = error?.response?.status;

            if (status == 422) {
                setError({
                    message: error?.response?.detail,
                    status: 422
                })

            } else if (status == 400) {
                setError({
                    message: error?.response?.detail,
                    status: 400
                })
            }

            else setError({
                message: error?.message,
                status: 500
            })

            setRequestState(REQUESTSTATE.ERROR);

        } finally {
            actions.setSubmitting(false);
        }
    }

    return {
        error,
        requestState,
        registeredUser,

        handleUserProfileSubmit
    }
}