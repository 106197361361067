/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Stripe Elements
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Card } from "@mui/material";

import Swal from "sweetalert2";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";


// Components
import SoftButton from "components/SoftButton";
import { StripeSetupIntentProvider } from "context/StripeSetupContext";

// Hook to handle the logout
import { useLogin } from "hooks/useLogin";

import { STRIPEPAYMENTERRORMESSAGES } from "constants/exception";

function BillingForm({ customerID, returnURL }) {

    return (
        <StripeSetupIntentProvider customerID={customerID} >
            <BillingFormInner returnURL={returnURL} />
        </StripeSetupIntentProvider>
    )
}

BillingForm.propTypes = {
    customerID: PropTypes.string.isRequired,
    returnURL: PropTypes.string.isRequired,
};

function BillingFormInner({returnURL}) {

    const elements = useElements();
    const stripe = useStripe();
    const { logoutUser } = useLogin();
    const navigate = useNavigate();


    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const errors = await elements.submit();

        if (!stripe || !elements || errors?.message) {
            console.log("Error submitting form", errors);
            return Swal.fire("Error", `Ha ocurrido un error al validar el formulario: ${errors?.message}`, "error");
        }

        stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: returnURL,
            }
        })
        .then((result) => {
            if (result.error) {
                console.log("Error confirming setup", result.error);
                Swal.fire(STRIPEPAYMENTERRORMESSAGES[result.error.type], result?.error?.message || "Ha ocurrido un error inesperado", "error");
            }
            console.log("Confirmed", {result});
        })
        .catch((error) => {
            console.log("Unexpected error", error);
        });

    }

    const handleLogout = () =>{ 
        logoutUser();
        navigate("/authentication/sign-in");
    };

    return (
        <SoftBox>
            {/* Title */}
            <SoftTypography variant="h4" fontWeight="bold">
                Facturación
            </SoftTypography>

            {/* Subtitle */}
            <SoftTypography variant="button" fontWeight="regular" color="text">
                Datos de facturación para futuros pagos
            </SoftTypography>


            <SoftBox mt={1.625}>
                <form onSubmit={handleOnSubmit}>
                    <Card sx={{ height: "100%" }}>
                        <SoftBox p={2}>
                            <SoftBox>
                                
                                <AddressElement options={{ mode: "billing", display: {name: 'split'} }} />
                                <PaymentElement options={{ layout: "tabs" }} />

                                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                                    <SoftButton variant="contained" color="dark" onClick={handleLogout}>
                                        Cerrar sesión
                                    </SoftButton>
                                    <SoftButton type="submit" variant="contained" color="secondary">
                                        Guardar y continuar
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </form>
            </SoftBox>

        </SoftBox>
    );
}

// typechecking props for Payment
BillingFormInner.propTypes = {
    returnURL: PropTypes.string.isRequired,
};

export { BillingForm };
